import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { ClientOnly } from 'remix-utils/client-only'
import { CustomFlowbiteTheme, Drawer } from 'flowbite-react'
import { Sidebar } from '../Sidebar'
import { AreaSelector } from '../AreaSelector'
import { useNavigate } from '@remix-run/react'

export function Header() {
  const [isMoveAreaOpen, setIsMoveAreaOpen] = useState(false)
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false)
  const navigate = useNavigate()

  const customTheme: CustomFlowbiteTheme['drawer'] = {
    root: {
      position: {
        right: {
          on: 'right-0 top-11 h-screen w-40 translate-none',
          off: 'right-0 top-11 h-screen w-40 translate-x-full',
        },
      },
    },
  }

  return (
    <>
      <header className="flex left-0 z-20 top-0 w-full h-10 justify-between absolute bg-primary p-2 text-white">
        <div className="w-20">
          <button className="w-4" onClick={() => setIsMainMenuOpen((s) => !s)}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div>
          <img src="/banner.png" alt="今ここ空いてる？を知りたい私の imako" />
        </div>
        <div>
          <button
            onClick={() => setIsMoveAreaOpen((s) => !s)}
            className={`border ${
              isMoveAreaOpen ? 'border-primary bg-white text-black' : 'border-white'
            } border-1 text-xs px-2 py-1 rounded-lg`}
          >
            エリア移動
          </button>
        </div>
      </header>
      <ClientOnly>
        {() => (
          <>
            <Drawer open={isMainMenuOpen} onClose={() => setIsMainMenuOpen(false)}>
              <Drawer.Items>
                <Sidebar onMainMenuClick={() => setIsMainMenuOpen(false)} />
              </Drawer.Items>
            </Drawer>
            <Drawer
              open={isMoveAreaOpen}
              onClose={() => setIsMoveAreaOpen(false)}
              position="right"
              theme={customTheme}
              backdrop={false}
            >
              <Drawer.Items>
                <AreaSelector
                  onAreaClick={(area) => {
                    setIsMoveAreaOpen(false)
                    navigate(`area/${area}`)
                  }}
                />
              </Drawer.Items>
            </Drawer>
          </>
        )}
      </ClientOnly>
    </>
  )
}
