import style from './style.module.css'

type Props = React.PropsWithChildren<{
  crowdedLevel: string
  imageUrl: string
  highlight?: boolean
  markerCount?: number
  withArrow?: boolean
}>

function getOutlineColor(crowdedStyle: string): string {
  switch (crowdedStyle) {
    case 'noCrowded':
      return 'outline-notCrowded'
    case 'littleCrowded':
    case 'crowded':
      return 'outline-littleCrowded'
    case 'veryCrowded':
      return 'outline-veryCrowded'
    default:
      return 'outline-black/50'
  }
}

function getBackgroundColor(crowdedStyle: string): string {
  switch (crowdedStyle) {
    case 'noCrowded':
      return 'bg-notCrowded'
    case 'littleCrowded':
    case 'crowded':
      return 'bg-littleCrowded'
    case 'veryCrowded':
      return 'bg-veryCrowded'
    default:
      return 'bg-black/50'
  }
}

export function SpotHolder({
  crowdedLevel,
  imageUrl,
  highlight,
  withArrow = true,
  markerCount,
}: Props) {
  return (
    <>
      <div
        className={`
        mx-auto
        p-1
        rounded-full
        drop-shadow-lg
        w-fit
        relative
        ${highlight ? 'bg-[#FD7338]' : 'bg-white'}
        `}
      >
        {markerCount && (
          <div
            className={`
              absolute
              top-0
              right-0
              rounded-full
              text-white
              text-xs
              font-bold
              text-center
              w-5
              ${getBackgroundColor(crowdedLevel)}`}
          >
            {markerCount}
          </div>
        )}
        <div
          className={`
            ${highlight ? style.highlight : style.noHighlight}
            ${withArrow ? style.imakoSpotholder : ''}
            rounded-full
            outline 
            outline-offset-1
            outline-2
            ${getOutlineColor(crowdedLevel)}
            p-0
            bg-cover 
            bg-center
            ${highlight ? 'w-12 h-12 outline-[#FD7338]' : 'w-10 h-10 bg-white'}
            `}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        ></div>
      </div>
    </>
  )
}

export default SpotHolder
