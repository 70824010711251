import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'flowbite-react'
import { useEffect, useState } from 'react'

export function NotificationModal({
  onOk,
  onCancel,
  showModal,
}: {
  onOk: () => void
  onCancel: () => void
  showModal: boolean
}) {
  const [openModal, setOpenModal] = useState(showModal)

  const handleOk = () => {
    setOpenModal(false)
    onOk()
  }

  const handleCancel = () => {
    setOpenModal(false)
    onCancel()
  }

  useEffect(() => {
    setOpenModal(showModal)
  }, [showModal])

  return (
    <>
      <Modal show={openModal} size="md" onClose={handleCancel} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-sm font-normal text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon icon={faComment} />
              <span className="mx-2">imakoでお得な通知を受け取りませんか？</span>
            </h3>
            <div className="flex justify-center gap-4">
              <Button onClick={handleOk}>{'通知を受け取る'}</Button>
              <Button color="gray" onClick={handleCancel}>
                {'やめておく'}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
