import { useState } from 'react'
import { Latlng } from '../models/PositionType'

type GeolocationArgsType = {
  geolocationAvailable: boolean
  latitude?: number
  longitude?: number
  error?: string
}

export function useGeolocation() {
  const [currentPosition, setCurrentPosition] = useState<Latlng | undefined>()

  const initGeo = (onGeolocation?: (opts: GeolocationArgsType) => void) => {
    if ('geolocation' in navigator) {
      return navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          // to GA
          if (onGeolocation) 
            onGeolocation({
              geolocationAvailable: true,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
        },
        (error) => {
          console.error(error)
          if (onGeolocation) 
            onGeolocation({
              geolocationAvailable: false,
              error: error.message,
            })
        },
        { maximumAge: 0, timeout: 10000, enableHighAccuracy: false },
      )
    }
  }

  const removeGeo = (id: number) => {
    if (!id) return
    navigator.geolocation.clearWatch(id)
  }

  return { initGeo, removeGeo, currentPosition }
}
