import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { Menu } from './Menu'
import { Logo } from './Logo'

export function Sidebar({ onMainMenuClick }: { onMainMenuClick: () => void }) {
  return (
    <div className="bg-white overflow-y-auto">
      <button onClick={onMainMenuClick} className="absolute top-5 right-5">
        <FontAwesomeIcon icon={faAnglesLeft} />
      </button>
      <div className="my-12">
        <Logo />
      </div>
      <ul className="mt-5 ml-0">
        <li className="py-3">
          <Menu href="https://catnip-lungfish-a2a.notion.site/imako-e28b4424c5874d2ca33bd48bd52b21a8">
            アプリの使い方・よくある質問
          </Menu>
        </li>
        <li className="py-3">
          <Menu href="https://imakoapp.com/">運営会社について</Menu>
        </li>
        <li className="py-3">
          <Menu href="https://imakoapp.com/privacypolicy">個人情報の取り扱いについて</Menu>
        </li>
        <li className="py-3">
          <Menu href="https://imakoapp.com/termsofservice">利用規約</Menu>
        </li>
        <li className="py-3">
          <Menu href="https://docs.google.com/forms/d/e/1FAIpQLSdoasm7UVsaXq62P2vmxWh1lMEXtSl5VpK8RcpnyERrQv4L3w/viewform?embedded=true">
            フィードバック・ご要望
          </Menu>
        </li>
        <li className="py-3">
          <Menu href="https://imakoapp.com/contact">お問い合わせ</Menu>
        </li>
      </ul>
      <div className="text-center my-10">
        <a href="https://lin.ee/g3wIjuV">
          <img className="inline" src="/imako-welcome-line.png" />
        </a>
      </div>
    </div>
  )
}
