export function expandBounds(bounds: google.maps.LatLngBounds, offset = 0.001) {
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  return bounds
    .extend({ lat: sw.lat() - offset, lng: sw.lng() - offset })
    .extend({ lat: ne.lat() + offset, lng: ne.lng() + offset })
}

export function expandBoundsByMag(bounds: google.maps.LatLngBounds, mag: number = 2.0) {
  const center = bounds?.getCenter()
  if (!center) return

  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()
  // mag倍の広さを取得
  const yDistance = Math.abs(ne.lat() - sw.lat())
  const xDistance = Math.abs(ne.lng() - sw.lng())
  const bbox = [
    center.lat() - (yDistance / 2.0) * mag,
    center.lng() - (xDistance / 2.0) * mag,
    center.lat() + (yDistance / 2.0) * mag,
    center.lng() + (xDistance / 2.0) * mag,
  ]
  return { east: bbox[3], west: bbox[1], south: bbox[0], north: bbox[2] }
}
