export type Props = React.PropsWithChildren<{ href: string, children: string }>

export function Menu({href, children}: Props) {
  return (
    <a href={href} 
       target="_blank"
       rel="noopener noreferrer"
       type="button"
       className="rounded-lg border border-transparent bg-transparent text-center text-sm font-medium text-gray-700 shadow-none transition-all hover:bg-gray-100 disabled:bg-transparent disabled:text-gray-400">
      {children}
    </a>
  )
}
  