/**
 * It is possible to pass custom headers for each request. `request()` and `rawRequest()` accept a header object as the third parameter
 */

import { GraphQLClient } from 'graphql-request'

export async function graphqlRequestWithEndpoint<T>(
  endpoint: string,
  document: string,
  token: string,
  variables?: object,
  requestHeaders?: HeadersInit,
) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return await client.request<T>(document, variables, requestHeaders)
}

export async function graphqlRequest<T>(
  document: string,
  token: string,
  variables?: object,
  requestHeaders?: HeadersInit,
) {
  const endpoint = import.meta.env.VITE_GRAPHQL_ENDPOINT ?? 'https://localhost:3001/graphql'
  return await graphqlRequestWithEndpoint<T>(endpoint, document, token, variables, requestHeaders)
}

export async function graphqlRequestFromClient<T>(
  document: string,
  token: string,
  variables?: object,
  requestHeaders?: HeadersInit,
) {
  const endpoint =
    import.meta.env.VITE_GRAPHQL_FROM_CLIENT_ENDPOINT ?? 'https://localhost:3001/graphql'
  return graphqlRequestWithEndpoint<T>(endpoint, document, token, variables, requestHeaders)
}
