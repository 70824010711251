import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useCallback, useState } from 'react'
import { SpotsQueryDocument, SpotsQueryQuery } from '~/graphql/generated/graphql'
import { waitUser, WaitUserPromiseArgType } from '~/utils/firebase.client'
import { expandBoundsByMag } from '~/utils/geoutil'
import { graphqlRequestFromClient } from '~/utils/graphqlRequest'

export function useSpotsQuery() {
  const corelib = useMapsLibrary('core')
  const [previousBbox, setPreviousBbox] = useState<google.maps.LatLngBounds | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<SpotsQueryQuery | null>(null)

  const reloadFunc = useCallback(
    async (_cache = true, bbox?: number[]) => {
      const { token } = (await waitUser()) as WaitUserPromiseArgType
      setLoading(true)
      try {
        setData(await graphqlRequestFromClient(SpotsQueryDocument.toString(), token, { bbox }))
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setData],
  )

  const reloadFuncWithBbox = useCallback(
    (cache: boolean, bounds?: google.maps.LatLngBounds | undefined, mag: number = 2.0) => {
      if (!corelib) return
      if (!bounds) return reloadFunc(cache)

      const newBounds = expandBoundsByMag(bounds, mag)
      if (newBounds) {
        reloadFunc(cache, [newBounds.south, newBounds.west, newBounds.north, newBounds.east])
        setPreviousBbox(() => new corelib.LatLngBounds(newBounds))
      }
    },
    [corelib, reloadFunc],
  )

  return { reloadFuncWithBbox, loading, data, previousBbox }
}
