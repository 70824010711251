import { z } from 'zod'

export const enqueteSchema = z
  .object({
    nickname: z.string(),
    sex: z.string(),
    ageGroup: z.string(),
  })

export type EnqueteSchemaInput = z.infer<typeof enqueteSchema>
