import { useState } from 'react'
import { FirstEnquete } from '~/components/FirstEnquete'

export function useFirstEnquete(showEnquete: boolean) {
  const [showFirstEnquete, setShowFirstEnquete] = useState(showEnquete)

  const Enquete = showFirstEnquete && <FirstEnquete />

  return { Enquete, showFirstEnquete, setShowFirstEnquete }
}
